label {
  /* set this to inline if you want labels to the left of fields */
  display: block;
}

/* eliminate task tabs (view/edit/devel) */

ul.tabs.primary {
  display: none;
}

/* eliminate field collection links */

ul.field-collection-view-links {
  display: none;
}

.field-collection-view .field-label {
  width: inherit;
}

.bja_step h1 {
  clear: both;
}

/* specific styles */

.group-tta-dates > .fieldset-wrapper {
  width: 100%;
}

.group-tta-dates > .fieldset-wrapper > div {
  float: left;
  width: 30%;
  padding-right: 3%;
}

/* multistep buttons */

.multistep-form div.form-actions {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.multistep-form div.form-actions::after {
  clear: both;
}

.multistep-form div.form-actions .action-save {
  text-align: right;
}

.multistep-form div.form-actions .action-save-continue,
.multistep-form div.form-actions .action-preview-center {
  text-align: center;
}

.multistep-form div.form-actions [class*=action-] {
  padding-top: 5px;
}

.multistep-form div.form-actions [class*=action-] button {
  width: 100%;
}

#web-application-node-form,
#deliverable-node-form {
  margin-bottom: 50px;
  padding: 15px 5px 0;
}

/* ------------ TTA Multistep buttons at the top --------------- */

div.tta-chevrons .chevron button.disabled,
div.dlv-chevrons .chevron button.disabled {
  cursor: auto;
}

[class*=-chevrons] {
  overflow: hidden;
  height: 80px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  -webkit-mask-image: linear-gradient(0deg, rgba(255, 255, 255, .8) 0%, #ffffff 50%, #ffffff 50%, rgba(255, 255, 255, .8) 100%);
          mask-image: linear-gradient(0deg, rgba(255, 255, 255, .8) 0%, #ffffff 50%, #ffffff 50%, rgba(255, 255, 255, .8) 100%);
}

[class*=-chevrons] > .chevron > button {
  position: relative;
  width: 100%;
  height: 80px;
  margin-right: 30px;
  margin-left: 0px;
  margin-top: 0px;
  border: 0px;
  color: #ffffff;
  font-size: 10pt;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  opacity: 1 !important;
  background: none;
  background-color: transparent;
  cursor: pointer;
}

[class*=-chevrons] > .chevron > button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: calc(100% + 20px);
  left: -10px;
  transform: skew(25deg, 0deg);
}

[class*=-chevrons] > .chevron > button::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  height: 50%;
  width: calc(100% + 20px);
  left: -10px;
  transform: skew(-25deg, 0deg);
}

[class*=-chevrons] > .chevron > button.first::before {
  width: calc(100% + 35px);
  left: -25px;
}

[class*=-chevrons] > .chevron > button.first::after {
  width: calc(100% + 35px);
  left: -25px;
}

[class*=-chevrons] > .chevron > button.last::before {
  width: calc(100% + 35px);
}

[class*=-chevrons] > .chevron > button.last::after {
  width: calc(100% + 35px);
}

[class*=-chevrons] > .chevron > button.active::before,
[class*=-chevrons] > .chevron > button.active::after {
  background: #ff9933;
  background: linear-gradient(90deg, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%);
  z-index: -10;
}

[class*=-chevrons] > .chevron > button.inactive::before,
[class*=-chevrons] > .chevron > button.inactive::after {
  background: #6f6f6d;
  background: linear-gradient(90deg, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%);
  z-index: -10;
}

[class*=-chevrons] > .chevron > button.passive::before,
[class*=-chevrons] > .chevron > button.passive::after {
  background: #447ed4;
  background: linear-gradient(90deg, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%);
  z-index: -10;
}

[class*=-chevrons] > .chevron > button:hover,
[class*=-chevrons] > .chevron > button:focus,
[class*=-chevrons] > .chevron > button:active {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  background: none;
  background-color: transparent;
}

/* THESE HOVER EFFECTS DO NOT WORK!!! */

/*
.chevron > button:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: calc(100% + 200px);
  left: -10px;  

  background-color: #FF9900;
  background: #FF9900;

  -webkit-transform: skew(25deg, 0deg);
  -moz-transform: skew(25deg, 0deg);
  -ms-transform: skew(25deg, 0deg);
  -o-transform: skew(25deg, 0deg);
  transform: skew(25deg, 0deg);
}

.chevron > button:hover::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  height: 50%;
  width: calc(100% + 20px);
  left: -10px;
  
  background-color: #FF9900;
  background: #FF9900;

  -webkit-transform: skew(-25deg, 0deg);
  -moz-transform: skew(-25deg, 0deg);
  -ms-transform: skew(-25deg, 0deg);
  -o-transform: skew(-25deg, 0deg);
  transform: skew(-25deg, 0deg);
}

.chevron > button.first:hover::before {    
  width: calc(100% + 35px);
}

.chevron > button.first:hover::after {
  width: calc(100% + 35px);
}

.chevron > button.last:hover::before {
  width: calc(100% + 35px);
}

.chevron > button.last:hover::after {
  width: calc(100% + 35px);
}

.chevron > button.active:hover::before,
.chevron > button.active:hover::after {
  background: #ff9933; 
  background: linear-gradient(90deg, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%);
  z-index: -10; 
}

.chevron > button.inactive:hover::before,
.chevron > button.inactive:hover::after {
  background: #6f6f6d;
  background: linear-gradient(90deg, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%);
  z-index: -10;
}

.chevron > button.passive:hover::before,
.chevron > button.passive:hover::after {
  background: #447ed4;
  background: linear-gradient(90deg, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%);
  z-index: -10;
}
*/

@media screen and (max-width: 1023px) {
  [class*=-chevrons] {
    height: 110px;
  }

  [class*=-chevrons] > .chevron > button {
    height: 110px;
  }

  [class*=-chevrons] > .chevron > button.first {
    left: -5px;
    width: calc(100% + 5px);
  }
}

/*@media screen and (max-width: 479px) {
    [class*=col-xs-] {
        padding-left: 5px;
        padding-right: 5px;
    }
}*/

@media screen and (max-width: 767px) {
  div.region-content {
    padding: 5px !important;
  }

  .multistep-form div.form-actions .action-preview {
    text-align: left;
  }

  [class*=-chevrons] {
    width: 100%;
    height: 560px;
    margin-bottom: 10px;
    -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, .8) 0%, #ffffff 50%, #ffffff 50%, rgba(255, 255, 255, .8) 100%);
            mask-image: linear-gradient(90deg, rgba(255, 255, 255, .8) 0%, #ffffff 50%, #ffffff 50%, rgba(255, 255, 255, .8) 100%);
  }

  /* For devliverables, we just need to shorten the height a bit since there are only 5 chevrons */

  .chevron_columns_5 {
    height: 460px;
  }

  [class*=-chevrons] > .chevron > button {
    padding: 0px;
    margin-bottom: 40px;
    height: 60px;
    cursor: pointer;
    font-size: 12pt;
    /* This will offset the button text down a bit to "center" it relative to overall chevron shape */
    vertical-align: bottom;
    line-height: 75px;
  }

  [class*=-chevrons] > .chevron > button.last {
    vertical-align: middle;
  }

  [class*=-chevrons] > .chevron > button::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    height: 155%;
    width: 51% !important;
    transform: skew(0deg, 6deg);
  }

  [class*=-chevrons] > .chevron > button::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    height: 155%;
    width: 50% !important;
    transform: skew(0deg, -6deg);
  }

  [class*=-chevrons] > .chevron > button.first {
    width: 100%;
    left: 0px;
  }

  [class*=-chevrons] > .chevron > button.first::before {
    left: 0px;
  }

  [class*=-chevrons] > .chevron > button.first::after {
    left: 50%;
    margin-right: 10px;
  }

  [class*=-chevrons] > .chevron > button.active::before,
  [class*=-chevrons] > .chevron > button.active::after {
    background: linear-gradient(0deg, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%, rgba(255, 153, 51, 1) 100%);
  }

  [class*=-chevrons] > .chevron > button.inactive::before,
  [class*=-chevrons] > .chevron > button.inactive::after {
    background: linear-gradient(0deg, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%, rgba(83, 83, 83, 1) 100%);
  }

  [class*=-chevrons] > .chevron > button.passive::before,
  [class*=-chevrons] > .chevron > button.passive::after {
    background: linear-gradient(0deg, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%, rgba(67, 125, 212, 1) 100%);
  }
}

/* We need to override the default bootsrap width assigned to a col-xx-2 sized column */

@media (min-width: 1280px) {
  .chevron_columns_4 .col-md-2 {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .chevron_columns_4 .col-sm-2 {
    width: 25%;
  }
}

@media (min-width: 1280px) {
  .chevron_columns_5 .col-md-2 {
    width: 20%;
  }
}

/* This is because deliverables have 5 chevrons taking up a 12 column grid */

@media (min-width: 768px) {
  .chevron_columns_5 .col-sm-2 {
    width: 20%;
  }
}

pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

#edit-field-performance-metrics {
  width: 100%;
  float: left;
}

fieldset.group-demographics {
  clear: left;
}
/* === BEGIN: Style teasers for ntk_info and ntk_resources === */

/*
  The H1 headings use the styles written for the H2 headings
  on Tools & Resources (/tools). See the nttac theme, nttac.css,
  To find these things in nttac.css, please search for the following:
      == Bookmark:ntk_info-headings ===
*/

/* Add margin atop the exposed filters in case nothing else separates them
  from the print/email/PDF buttons.
*/

form#views-exposed-form-ntk-info-search {
  margin-top: 1.5em;
}

/* Style the heading above attached resources: */

.view-ntk-info .views-field-field-ntk-resources .views-label {
  display: block;
  font-weight: bold;
  margin: 1em 0 0.5em;
  color: #013366;
}

.view-ntk-info .views-field-field-ntk-resources .field-label-inline {
  /* Tighten up spacing of teasers: */
  line-height: 1em;
}

div.views-row .node-ntk-resource {
  border: 1px solid gray;
}

.views-row .node-ntk-resource .group-left {
  float: left;
  width: 15%;
}

.views-row .node-ntk-resource .group-right {
  width: 85%;
  float: left;
  clear: none;
}

.views-row .node-ntk-resource .group-right .field .clearfix::after {
  clear: none;
}

.views-row .node-ntk-resource .group-right .field .clearfix::before {
  clear: none;
}

/* resource when shown in ntk_info as block */

div.view-ntk-info .node-ntk-resource .group-header h2,
.views-row .node-ntk-resource .group-header h2 {
  font-weight: bold;
  font-size: 12px;
  background-color: #666;
  margin: 0;
  padding: 3px;
}

#block-system-main .views-row .node-ntk-resource .group-header h2 a {
  padding-left: 1em;
  color: white;
  text-decoration: none;
}

.views-row .node-ntk-resource .field {
  padding: .3em;
}

.views-row .node-ntk-resource .field-name-title {
  padding: 0;
}

.node-ntk-info.view-mode-full article .field {
  padding: .3em;
  display: block;
}

/* div.view-ntk-info article describes teasers (or full posts)
  listed by the displays of the ntk-info view.
 */

div.view-taxonomy-term article .field > .field-name-field-image,
div.view-ntk-info article .field.field-name-field-image,
.node-ntk-info.view-mode-full .field.field-name-field-image {
  float: left;
  /* margin: 0.25em 1em 0.5em 0; */
  /* 1em = 13px */
  margin: 3px 13px 6px 0;
}

div.view-ntk-info article .field,
.node-ntk-info.view-mode-full article .field {
  margin-top: 1px;
  padding-top: 13px;
}

.node-ntk-info.view-mode-full .node-ntk-resource h2 {
  /* Change resource teaser's heading to size of h2. */
  /* relative sizes like smaller, 90% did not work */
  font-size: 14px;
}

/* float: none makes resource details list properly beside the image. */

.node-ntk-info article.node-ntk-resource section .field-items,
.views-field-field-ntk-resources article.node-ntk-resource section .field-items,
.view-taxonomy-term article.node-ntk-resource section .field-items {
  float: none;
}

/* Resource-specific styling: */

.node-ntk-resource .field section.clearfix:after {
  clear: none;
}

/* Keep resource dates and contributors on separate lines: */

.node-ntk-resource section.field-label-inline .field-items {
  float: none;
}

/* Style contributors and the like: */

article section.field-label-inline ul.field-items,
article section.field-label-inline li.field-item {
  /*  display: inline-block;*/
  float: none;
}

/* === END: Style teasers for ntk_info and ntk_resources === */

/* ntk */

.view-ntk-info.view-display-id-search .views-row,
.view-ntk-info.view-display-id-search_mixed article {
  border: 1px solid gray;
  margin-bottom: 5px;
  padding-left: .5em;
  padding-right: .5em;
}

.view-ntk-resource .views-row > article > group-header h2 {
  background-color: #0071bc;
  margin: 10px 0px 0px 0px;
  padding: 3px;
}

.view-ntk-resource .views-row > article > header h2 a {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.view-ntk-info .improvedselect {
  background: none repeat scroll 0 0 #eee;
  border: 1px solid gray;
  padding: 1%;
}

.view-ntk-info .view-footer {
  text-align: center;
}

/* make the orphaned resources visibly separate from content above */

.view-ntk-info .attachment {
  border-top: 1px solid gray;
  margin-top: 2em;
  padding-top: 1em;
}

.attachment-after .view-header {
  margin-bottom: 1em;
}

/* ntk_resource in view mode : default */

.node-ntk-info.view-mode-full  > div,
.node-ntk-resource.view-mode-full  > div {
  padding-right: 1em;
}

.node-ntk-info.view-mode-full .group-left,
.node-ntk-resource.view-mode-full .group-left {
  width: 20%;
}

.node-ntk-info.view-mode-full  .group-middle,
.node-ntk-resource.view-mode-full .group-middle {
  width: 40%;
}

.node-ntk-info.view-mode-full  .group-right,
.node-ntk-resource.view-mode-full .group-right {
  width: 30%;
  padding: .5em;
  border: 1px solid gray;
  background-color: white;
}

/* Because ntk_info doesn't have list of content atop the body,
    let the footer wrap around the image.
*/

.node-ntk-info.view-mode-full > .group-footer {
  clear: right;
}

/* ntk_resource in view mode : NTK Info teaser wide */

.view-mode-teaser-wide .node-ntk-info .group-left,
.view-mode-teaser-wide .node-ntk-resource .group-left {
  width: 20%;
}

.view-mode-teaser-wide .node-ntk-info .group-middle,
.view-mode-teaser-wide .node-ntk-resource .group-middle {
  width: 40%;
}

.view-mode-teaser-wide .node-ntk-info .group-right,
.view-mode-teaser-wide .node-ntk-resource .group-right {
  padding: .5em;
  border: 1px solid gray;
  background-color: white;
}

.view-display-id-browse .ui-accordion .views-field-field-image {
  float: left;
  margin-right: 1em;
}

/* full naloxone toolkit menu */

/* skip funky menu styling on expanded menus - way too complicated */

.sidebar #block-naloxone-ntk-menu ul.menu li.leaf {
  background: inherit;
}

.sidebar #block-naloxone-ntk-menu ul.menu li.expanded {
  background: inherit;
}

.sidebar #block-naloxone-ntk-menu ul.menu ul {
  padding-left: 15px;
}

.sidebar #block-naloxone-ntk-menu ul.menu ul li.leaf a:link {
  list-style-image: url("nttac/naloxone/images/arrowlink_filled.png");
  padding-bottom: 0px;
}

.sidebar #block-naloxone-ntk-menu ul.menu ul li.last {
  margin-bottom: 0px;
}

/* hack to hide the referenced resources on the search-mixed display  */

.view-display-id-search_mixed .field-name-field-ntk-resources {
  display: none;
}

#main-content .node-ntk-resource .field-type-image img {
  padding-bottom: 5px;
  /* stop IE 8 from letting image exceed parent boundaries */
  max-width: 100%;
  max-height: 150px;
}

/*
 * Style the naloxone banner block above all naloxone content pages 
 * A few things are going wrong here:
 * Specifying background-repeat : no-repeat here does nothing.  I had to
 * put it in the actual block PHP code as a style attribute.
 * The block puts naloxone_banner inside another <div> to center it, but
 * it only works if we give the banner a fixed width.
 * 
 */

#naloxone_banner {
  background: url('../images/naloxone_banner.png');
  background-size: cover;
  margin-top: 20px;
  height: 100%;
  min-height: 175px;
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.pquote {
  float: right;
  width: 420px;
  background: url('../images/quote.png') top left no-repeat;
  font-family: Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif;
  color: #002754;
  font-size: 22px;
  line-height: 1.2;
  font-style: italic;
  padding: 13px;
}

blockquote {
  margin: 0;
}

.pquote p:first-letter {
  font-size: 36px;
  font-weight: bold;
}

.view-content {
  clear: both;
}

.view-ntk-info .view-header .video-box {
  margin: 0 0 1em 1em;
  float: right;
  border: 3px solid #0071bc;
  width: 260px;
  padding: 2px;
}



.view-ntk-info .view-header .video-box .caption {
  margin: 0;
  text-align: center;
}

/* 
 * The move to Bootstrap has played around with the markup, so some of the
 * rules above are iffy and maybe useless.  Teasing them apart is going to
 * be a real pain.
 */

.view-ntk-info .node-ntk-resource h2 {
  font-size: 12px;
}
#views-exposed-form-ntk-info-search .improvedselect {
  /*margin-bottom: 4%;*/
  /*margin-right: 6%;*/
  /*max-width: 336px;*/
  /*min-width: 330px;*/
}

/* Set sizes on .views-exposed-widget to work better in IE. 359px was too big */

body #views-exposed-form-ntk-info-search .views-exposed-form .views-exposed-widget {
  min-width: 352px;
  margin: 0 8px 8px 0;
}

body #views-exposed-form-ntk-info-search .views-exposed-form .views-widget-per-page,
body #views-exposed-form-ntk-info-search .views-exposed-form .views-submit-button {
  /* Put per-page and submit widgets side-by-side. */
  min-width: 170px;
  max-width: 175px;
}

#views-exposed-form-ntk-info-search .views-exposed-form .improvedselect {
  /* override min-width in favor of width of .views-exposed-widget */
  min-width: 0;
}

/* Hide the filters' search box's */

#views-exposed-form-ntk-info-search .improvedselect_filter_box,
.improvedselect-text-wrapper {
  display: none;
}

#views-exposed-form-ntk-info-search .improvedselect ul.improvedselect_sel,
#views-exposed-form-ntk-info-search .improvedselect ul.improvedselect_all {
  width: 44%;
  /* 132px is the width at 43 or 44 % */
  /* Prevents expansion of column when moving items */
  max-width: 154px;
}

#views-exposed-form-ntk-info-search .improvedselect .improvedselect_control {
  width: 8%;
  margin-left: 1%;
  margin-right: 1%;
}

#views-exposed-form-ntk-info-search  .improvedselect ul.improvedselect_sel,
#views-exposed-form-ntk-info-search .improvedselect ul.improvedselect_all {
  /* Min-height avoids problems caused by module calculating height
   * after removal of filter box.
   */
  min-height: 6.6em;
  /* Max-height matches height of buttons: */
  max-height: 7em;
}

#views-exposed-form-ntk-info-search .improvedselect ul li {
  font-size: 88%;
  /* List entries on one line, but no horizontal scroll bar: */
  /*  overflow-x: hidden;
  white-space: nowrap;*/
}

/* Indent all but first line of multi-line items in exposed filters. */

#views-exposed-form-ntk-info-search .improvedselect_all > li,
#views-exposed-form-ntk-info-search .improvedselect_sel > li {
  margin-left: 0.5em;
  text-indent: -0.5em;
}

#views-exposed-form-ntk-info-search .views-widget-filter-combine input {
  width: 330px;
}

#views-exposed-form-ntk-info-search .views-exposed-widget.views-submit-button {
  text-align: right;
  /*  width: 330px;*/
  width: 150px;
}

#views-exposed-form-ntk-info-search .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 2.2em;
}

#views-exposed-form-ntk-info-search .views-exposed-widget.views-widget-per-page {
  margin-top: 19.5px;
  width: 180px;
}

#views-exposed-form-ntk-info-search .form-item.form-type-select.form-item-items-per-page {
  margin-top: 1em;
}

#views-exposed-form-ntk-info-search  .views-widget-per-page label {
  font-weight: bold;
  margin-top: 0.5em;
}

/* Dropdown selector of number of items: */

#views-exposed-form-ntk-info-search select#edit-items-per-page {
  min-width: 5em;
}

#views-exposed-form-ntk-info-search  .views-exposed-form,
#views-exposed-form-ntk-info-search  .views-exposed-form .views-exposed-widget {
  padding: 0;
  margin: 0;
}
#views-exposed-form-ntk-info-search-mixed .improvedselect {
  /*margin-bottom: 4%;*/
  /*margin-right: 6%;*/
  /*max-width: 336px;*/
  /*min-width: 330px;*/
}

/* Set sizes on .views-exposed-widget to work better in IE. 359px was too big */

body #views-exposed-form-ntk-info-search-mixed .views-exposed-form .views-exposed-widget {
  min-width: 352px;
  margin: 0 8px 8px 0;
}

body #views-exposed-form-ntk-info-search-mixed .views-exposed-form .views-widget-per-page,
body #views-exposed-form-ntk-info-search-mixed .views-exposed-form .views-submit-button {
  /* Put per-page and submit widgets side-by-side. */
  min-width: 170px;
  max-width: 175px;
}

#views-exposed-form-ntk-info-search-mixed .views-exposed-form .improvedselect {
  /* override min-width in favor of width of .views-exposed-widget */
  min-width: 0;
}

/* Hide the filters' search box's */

#views-exposed-form-ntk-info-search-mixed .improvedselect_filter_box,
.improvedselect-text-wrapper {
  display: none;
}

#views-exposed-form-ntk-info-search-mixed .improvedselect ul.improvedselect_sel,
#views-exposed-form-ntk-info-search-mixed .improvedselect ul.improvedselect_all {
  width: 44%;
  /* 132px is the width at 43 or 44 % */
  /* Prevents expansion of column when moving items */
  max-width: 154px;
}

#views-exposed-form-ntk-info-search-mixed .improvedselect .improvedselect_control {
  width: 8%;
  margin-left: 1%;
  margin-right: 1%;
}

#views-exposed-form-ntk-info-search-mixed  .improvedselect ul.improvedselect_sel,
#views-exposed-form-ntk-info-search-mixed .improvedselect ul.improvedselect_all {
  /* Min-height avoids problems caused by module calculating height
   * after removal of filter box.
   */
  min-height: 6.6em;
  /* Max-height matches height of buttons: */
  max-height: 7em;
}

#views-exposed-form-ntk-info-search-mixed .improvedselect ul li {
  font-size: 88%;
  /* List entries on one line, but no horizontal scroll bar: */
  /*  overflow-x: hidden;
  white-space: nowrap;*/
}

/* Indent all but first line of multi-line items in exposed filters. */

#views-exposed-form-ntk-info-search-mixed .improvedselect_all > li,
#views-exposed-form-ntk-info-search-mixed .improvedselect_sel > li {
  margin-left: 0.5em;
  text-indent: -0.5em;
}

#views-exposed-form-ntk-info-search-mixed .views-widget-filter-combine input {
  width: 330px;
}

#views-exposed-form-ntk-info-search-mixed .views-exposed-widget.views-submit-button {
  text-align: right;
  /*  width: 330px;*/
  width: 150px;
}

#views-exposed-form-ntk-info-search-mixed .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 2.2em;
}

#views-exposed-form-ntk-info-search-mixed .views-exposed-widget.views-widget-per-page {
  margin-top: 19.5px;
  width: 180px;
}

#views-exposed-form-ntk-info-search-mixed .form-item.form-type-select.form-item-items-per-page {
  margin-top: 1em;
}

#views-exposed-form-ntk-info-search-mixed  .views-widget-per-page label {
  font-weight: bold;
  margin-top: 0.5em;
}

/* Dropdown selector of number of items: */

#views-exposed-form-ntk-info-search-mixed select#edit-items-per-page {
  min-width: 5em;
}

#views-exposed-form-ntk-info-search-mixed  .views-exposed-form,
#views-exposed-form-ntk-info-search-mixed  .views-exposed-form .views-exposed-widget {
  padding: 0;
  margin: 0;
}